<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="q-mb-sm">
        <h6 class="title-h6 q-mt-none">{{$t('processes.linked_entity')}}</h6>
        <div class="row">
          <EntityCard class="col-6" :showSeparator="false" v-if="linkedEntity" :model="linkedEntity" @menuClick="onMenuClick($event)" />
          <div class="q-pl-sm add-agent-card col-6" v-else>
            <search-box v-if="searchBoxActivated"
              @input="onSelectLinkedObj"
              model_type="entity"
              :label="$t('processes.fields.entity.label')"
              :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true }"
              :color="color" />
            <base-add-button v-else :label="$t('processes.add_entity')" :color="color" @click="searchBoxActivated = true" />
          </div>
        </div>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.informations')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="justify-center q-gutter-sm">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_entree_dans_process')" :color="color"
                v-model="formData.date_entree_dans_process" ref="label" />
              <base-input-select class="col q-pa-none q-mt-none" v-bind="formInputProps('type_process')" :color="color"
                v-model="formData.type_process" ref="label" />
            </div>
            <div class="row q-ml-md">
              <div class="col-6">
                <base-input-date class="" v-bind="formInputProps('date_previsionnelle_sortie_process')" :color="color"
                  v-model="formData.date_previsionnelle_sortie_process" ref="label" />
              </div>
            </div>
            <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.commentaires.label')}}</q-item-label>
            <base-input-text class="q-px-sm row" placeholder="" label="" v-bind="formInputProps('commentaire')" :color="color"
              v-model="formData.commentaire" ref="label" />
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.planAction')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card v-for="planAction in relatedPlanActions" :key="planAction.id" flat bordered class="q-pa-sm q-mb-sm">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-date :readonly="true" class="col" :label="$t('processes.fields.date_demande.label')" :color="color"
                    v-model="planAction.date_demande" />
                <base-input-date :readonly="true" class="col" :label="$t('processes.fields.date_visee.label')" :color="color"
                    v-model="planAction.date_visee" />
              </div>
              <div class="row">
                <div class="col-6">
                  <base-input-date :readonly="true" class="" :label="$t('processes.fields.date_realisation.label')" :color="color"
                    v-model="planAction.date_realisation" />
                </div>
              </div>
              <div class="row q-gutter-sm">
                <base-input-text :readonly="true" class="col" :label="$t('processes.fields.responsable.label')" :color="color"
                    v-model="planAction.responsable" />
                <base-input-text :readonly="true" class="col" :label="$t('processes.fields.libelle_demande.label')" :color="color"
                    v-model="planAction.libelle_demande" />
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mt-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" :label="$t('processes.fields.date_demande.label')" :color="color"
                    value="" v-model="planAction.date_demande" />
                <base-input-date class="col" :label="$t('processes.fields.date_visee.label')" :color="color"
                    value="" v-model="planAction.date_visee" />
              </div>
              <div class="row">
                <div class="col-6">
                  <base-input-date class="" :label="$t('processes.fields.date_realisation.label')" :color="color"
                    v-model="planAction.date_realisation" />
                </div>
              </div>
              <div class="row q-gutter-sm">
                <base-input-text class="col" :label="$t('processes.fields.responsable.label')" :color="color"
                    value="" v-model="planAction.responsable" />
                <base-input-text class="col" :label="$t('processes.fields.libelle_demande.label')" :color="color"
                    value="" v-model="planAction.libelle_demande" />
              </div>
              <div class="row q-mt-md justify-end">
                <div class="col col-3 q-py-sm q-px-md">
                  <q-btn class="full-width" color="process" size="sm" @click="addPlanAction()" :disabled="!isUpdate">{{$t('processes.enregistrer')}}</q-btn>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
      </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { EntityCard } from '../../../components/entities'
import { BaseForm } from '../../../mixins'

export default {
  mixins: [BaseForm],
  components: { EntityCard },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      searchBoxActivated: false,
      linkedEntity: null,
      isUpdate: this.$route.params.id,
      // related plan actiosn
      relatedPlanActions: [],
      planAction: { },
      booleanOption: [
        { value: true, label: 'Oui' },
        { value: false, label: 'Non' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentSuiviDesProtocolesProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    })
  },

  watch: {
    linkedEntity (newVal, oldVal) {
      if (!this.formData.related_objects) this.formData.related_objects = []
      else this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'entities')
      if (newVal) this.formData.related_objects.push({ model_type: 'entities', model_id: newVal.id })
    },
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'agenceRouge'
        if (!newVal || !this.currentSuiviDesProtocolesProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options, type, boolean }) => ({ id, options, type, boolean }))
            .reduce((acc, { id, options, type, boolean }) => ({
              ...acc,
              [id]: options
                ? options.find(({ value }) => value === this.currentSuiviDesProtocolesProcess[id])
                : type === 'select' && boolean
                  ? this.booleanOption.find(val => val.value === this.currentSuiviDesProtocolesProcess[id])
                  : this.currentSuiviDesProtocolesProcess[id]
            }), {})
        }

        this.formData.related_objects = []
        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id

          // Set entity
          if (this.currentSuiviDesProtocolesProcess.entities) {
            this.linkedEntity = this.currentSuiviDesProtocolesProcess.entities.map(entity => ({
              ...entity,
              actions: [
                { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: entity.id } } },
                { label: this.$t('processes.create_task'), payload: { name: 'create-task', params: { id: entity.id, entity } } }
              ],
              model: { model_type: 'entities', model_id: entity.id }
            }))[0]
          }

          // Set actions
          if (this.currentSuiviDesProtocolesProcess.actions) {
            this.relatedPlanActions = this.currentSuiviDesProtocolesProcess.actions
            let planActionsFormDataMapped = this.relatedPlanActions.map(planAction => ({
              model_type: 'action',
              model_id: planAction.id
            }))
            this.formData.related_objects.push(...planActionsFormDataMapped)
          }
        }
      },
      immediate: true
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_des_protocoles'), to: { name: 'suivi-des-protocoles-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-des-protocoles-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_des_protocoles'))

    this.$store.commit('pages/setTabs', [
      { name: 'suivi-des-protocoles-form', label: 'Informations', to: { name: 'suivi-des-protocoles-form' }, params: { id: this.$route.params.id } }
    ])
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'agenceRouge' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'agenceRouge')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'agenceRouge')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    addPlanAction () {
      let params = {
        ...this.planAction,
        model_type: 'action',
        agence_rouge_id: this.$route.params.id
      }

      this.$store.dispatch('processes/saveProcesses', params).then(data => {
        this.relatedPlanActions.push({ id: data.id, ...this.planAction })
        this.planAction = {}
        this.formData.related_objects.push({
          model_type: 'action',
          model_id: data.id
        })

        this.notifySuccess('processes.action_added')
      })
    },
    onSelectLinkedObj (obj) {
      this.linkedEntity = {
        label: obj.value.label,
        id: obj.value.value,
        actions: [
          { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: obj.value.value } } },
          { label: this.$t('processes.create_task'), payload: { name: 'create-task', params: { id: obj.value.value, entity: obj.value } } }
        ]
      }
    },
    onMenuClick ({ name, params }) {
      this.hasChanged = true
      if (name === 'model-detach') {
        this.linkedEntity = null
        this.searchBoxActivated = false
      } else if (name === 'create-task') {
        this.$router.push(
          {
            name: 'task-create',
            params: {
              model_type: 'entity',
              model_object: params.entity,
              statut: 'BROUILLON',
              description: 'Suivi des protocoles et agences rouges'
            }
          }
        )
      }
    },
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'suivi-des-protocoles-form', params: { id } })
    },
    copyCour () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'agenceRouge', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .process-color
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0;
    top 7px;
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
</style>
